.continerStyling {
    /* background-color: #f5f5f5 !important; */
    padding: 1rem;
}

.errorMessageStyling {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
    font-size: 16px;
}

.chartContainerStyling {
    background-color: rgb(255, 255, 255) !important;
    padding: 14px 10px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.spinnerContainerStyle {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60vh;
}

.styleFilterRow {
    /* float: right; */
    margin-bottom: 2rem;
}

.smallSpinnerCenter {
    display: flex;
    justify-content: center;
}

.selectInputBox {
    width: 220px;
    margin-right: 20px;
    margin-bottom: 10px;
}

.selectInputNumBox {
    width: 100px;
    margin-right: 20px;
    margin-bottom: 10px;
}

.dateStyling {
    color: #6e8192;
    margin-top: 4px !important; 
    margin-left: 18px;
    font-weight: 500;
    white-space: nowrap;
}
.dateStyling2 {
    color: #6e8192;
    margin-top: 6px !important; 
    margin-left: 18px;
    font-weight: 500;
    white-space: nowrap;
}

.selectInputLastBox {
    width: 220px;
}

.ant-card-head-title {
    font-size: 19px;
}

.appointmentRowStyling {
    margin-top: 0.8rem !important;
}

.fontStylingYoY {
    font-size: 15px;
    white-space: nowrap;
    color: #646363;
    font-weight: 500;
    margin-top: -10px;
    margin-bottom: 10px;
}

.fontStylingTarget {
    font-size: 15px;
    white-space: nowrap;
    color: #646363;
    font-weight: 500;
}

.gauge-chart-container {
    max-width: 100%;
    margin: auto;
    margin-top: 0px;
}

.cardStyling {
    height: 10.5rem;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.ant-table-wrapper table {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.cardStyling .ant-card-head {
    /* border-bottom: none; */
}

.marginAdjustCalendarToggleButton {
    margin-left: 18px;
    margin-top: 3px;
    width: 23px;
}

.rdrDateRangePickerWrapper {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}

.date-picker-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 9999;
    top: 10.5rem;
    display: flex;
    justify-content: end;
    right: 30px;
}

.appointmentHeadingTitle {
    font-size: 14px;
    font-weight: 600;
    color: #646363;
}

.targetCompletedValueHeading {
    font-size: 16px;
    color: #646363;
    font-weight: 500;
}

.targetCompletedValueHeadingCount {
    font-size: 15px;
    color: #646363;
    font-weight: 500;
}

.paymentCompletedValueHeading {
    font-size: 16px;
    color: #646363;
    font-weight: 500;
}

.paymentCompletedValueHeadingCount {
    font-size: 15px;
    color: #646363;
    font-weight: 500;
}

.text-group text {
    display: none;
    /* Hide the percentage text */
}

.titleCardHeading {
    float: left;
    color: #646363;
    font-size: 17px;
    font-weight: 700;
}

.titleCardNumber {
    color: #1976d3;
    font-size: 30px;
    font-weight: 700;
}

.trendChartMarginAdjust {
    margin-top: -1.5rem !important;
}

.targetIconStyling {
    width: 21px;
    margin-left: 16px;
    margin-top: 4px;
}

.targetInputStyling {
    width: 11.5rem;
    margin-bottom: 12px;
}

.submitTargetBtn {
    position: relative;
    left: 6.8rem;
}

.refreshIconStyling {
    margin-left: 16px;
    margin-top: 3px;
    width: 23px;
}

.inputContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
    padding: 0 !important;
}

.marginTopAdjustSelect {
    margin-top: -25px !important;
}

.labelStylingFilter {
    white-space: nowrap;
}

.targetSelectInputBox {
    margin-bottom: 12px;
    margin-top: 12px;
}

.gray-box-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* Adjust as needed */
    background-color: rgba(128, 128, 128, 0.5);
    /* Gray color with some transparency */
}

.gray-box {
    border-radius: 10px;
    text-align: center;
    font-size: 20px;
}

/* Example of media query for tablets (768px wide and above) */
@media (min-width: 768px) {
    .date-picker-container {
        flex-direction: row;
        justify-content: space-between;
    }

    .gauge-chart-container {
        /* width: 50%; */
    }

}

/* Example of media query for tablets (767px size and below) */

@media (max-width: 550px) {
    .ant-card-head-title {
        font-size: 14px;
    }
}

@media (max-width: 767px) {
    .gauge-chart-container {
        width: 120px;
    }
}


@media (max-width: 1000px) {

    .selectInputBox {
        width: 140px;
    }

    .selectInputNumBox {
        width: 60px;
    }

    .selectInputLastBox {
        width: 140px;
    }

    .ant-card-head-title {
        font-size: 16px;
    }

    .gauge-chart-container {
        margin-top: -23px;
    }

    .cardStyling {
        height: 9rem;
    }

    .appointmentRowStyling {
        margin-top: 0rem !important;
    }

    .fontStylingYoY {
        font-size: 10px;
    }

    .targetCompletedValueHeading {
        font-size: 13px;
    }

    .targetCompletedValueHeadingCount {
        font-size: 13px;
    }

    .paymentCompletedValueHeading {
        font-size: 13px;
    }

    .paymentCompletedValueHeadingCount {
        font-size: 13px;
    }
}


@media (min-width: 1001px) and (max-width: 1199px) {

    .selectInputBox {
        width: 125px;
    }

    .selectInputNumBox {
        width: 40px;
    }

    .selectInputLastBox {
        width: 115px;
    }

    .labelStylingFilter {
        font-size: 11px;
    }

    .marginTopAdjustSelect {
        margin-top: -21px!important;
    }

    .ant-card-head-title {
        font-size: 17px;
    }

    .gauge-chart-container {
        /* margin-top: -8px; */
    }

    .cardStyling {
        height: 10rem;
    }

    .appointmentRowStyling {
        margin-top: 0.4rem !important;
    }

    .fontStylingYoY {
        font-size: 13px;
        margin-top: -3px;
    }

    .fontStylingTarget {
        font-size: 13px;
    }
}

@media (min-width: 1200px) and (max-width: 1300px) {

    .fontStylingYoY {
        font-size: 12px;
        margin-top: -3px;
    }

    .fontStylingTarget {
        font-size: 12px;
        white-space: unset;
    }

    .titleCardNumber {
        font-size: 26px;
    }

    .targetCompletedValueHeading {
        font-size: 12px;
        margin-left: 1rem;
    }

    .targetCompletedValueHeadingCount {
        font-size: 12px;
    }

    .paymentCompletedValueHeading {
        font-size: 12px;
    }

    .paymentCompletedValueHeadingCount {
        font-size: 12px;
    }

    .selectInputBox {
        width: 165px;
    }

    .selectInputNumBox {
        width: 60px;
    }

    .selectInputLastBox {
        width: 165px;
    }
}

@media (min-width: 1301px) and (max-width: 1550px) {

    .fontStylingYoY {
        font-size: 13px;
        margin-top: -4px;
    }

    .fontStylingTarget {
        font-size: 13px;
        white-space: unset;
    }

    .gauge-chart-container {
        /* margin-top: -6px; */
    }

    .targetCompletedValueHeading {
        font-size: 13px;
        margin-left: 1rem;
    }

    .targetCompletedValueHeadingCount {
        font-size: 13px;
    }

    .paymentCompletedValueHeading {
        font-size: 13px;
    }

    .paymentCompletedValueHeadingCount {
        font-size: 13px;
    }
}

@media (min-width: 1551px) {

    .gauge-chart-container {
        width: 200px !important;
    }

}