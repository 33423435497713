/* * {
    margin: auto;
    padding: 0;
    box-sizing: border-box;
  }
   */
  #dashboard-container {
    height: 88vh;
    padding: 1rem;
  }
  #dashboard-container .header-text {
    margin-bottom: 2rem;
  }

  .custom-apptHistory-table .ant-table-thead > tr > th,
.custom-apptHistory-table .ant-table-tbody > tr > td {
  border-right: 1px solid #a8a4a4;
  border-bottom: 1px solid #a8a4a4;
  border-top: 1px solid #a8a4a4;
}

#dashboard-container {
  height: 88vh;
  padding: 1rem;
}
#dashboard-container .header-text {
  margin-bottom: 2rem;
}
.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}
.refresh-btn {
  position: absolute;
  top: 3%;
  right: 3%;
}
.report-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.btn {
  position: absolute;
  top: 4%;
  right: 3%;
}
#sider,
#top-sider {
  height: inherit;
  width: 80px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
  z-index: 100;
}
#top-sider {
  height: 50px;
  width: inherit;
}

.report-container {
  height: 90%;
}

.ant-table-wrapper .ant-table-tbody > tr > td {
  position: relative;
  padding: 6px 16px !important;
  overflow-wrap: break-word;
  vertical-align: baseline;
}

.ant-menu-light .ant-menu-item-selected {
  background-color: #faad14;
}
@media screen and (max-width: 768px) {
  .responsive-container {
    padding: 0 10px; /* Adjust padding for smaller screens */
  }
  .flex {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .refresh-btn {
    position: absolute;
    top: 3%;
    right: 3%;
  }
  .report-container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }

  .responsive-table {
    font-size: 14px; /* Reduce font size for smaller screens */
  }

  .report-container{
    height: 90%;
  }

  .ant-table-wrapper .ant-table-tbody>tr>td{
    position: relative;
    padding: 6px 16px !important;
    overflow-wrap: break-word;
    vertical-align: baseline;
  }

  .ant-menu-light .ant-menu-item-selected{
    background-color: #faad14;
  }
}
  @media screen and (max-width: 768px) {
    .responsive-container {
        padding: 0 10px; /* Adjust padding for smaller screens */
    }

    .responsive-table {
        font-size: 14px; /* Reduce font size for smaller screens */
    }

    /* Adjust other styles as needed */
}

/* For screens between 769px and 1200px */
@media screen and (min-width: 769px) and (max-width: 1200px) {
  .responsive-container {
    padding: 0 20px; /* Adjust padding for medium-sized screens */
  }

  .responsive-table {
    font-size: 16px; /* Adjust font size for medium-sized screens */
  }

  /* Adjust other styles as needed */
}

/* For screens larger than 1200px */
@media screen and (min-width: 1200px) {
  .responsive-container {
    padding: 0 30px; /* Adjust padding for larger screens */
  }

  .responsive-table {
    font-size: 18px; /* Adjust font size for larger screens */
  }

  /* Adjust other styles as needed */
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: #e7e6e6;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.mobile-card {
  background-color: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  padding: 16px;
  margin-bottom: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.mobile-card h2 {
  font-size: 1.5rem;
  margin-bottom: 8px;
}

.mobile-card p {
  font-size: 1rem;
  margin: 8px 0;
}

.mobile-card-actions {
  margin-top: 16px;
  display: flex;
  justify-content: end;
  align-items: center;
}

.mobile-card-actions > * {
  cursor: pointer;
  margin-right: 8px;
}