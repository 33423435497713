.time {
  padding: 0.5rem 1.5rem;
  transition-property: border-color, box-shadow;
  transition-duration: 0.3s;
  border: 0.05rem solid #333;
  border-radius: 0.4rem;
  font-size: 0.7rem;
  font-weight: 300;
  line-height: 1;
  cursor: pointer;
  background-color: transparent;
}

.time:hover,
.time:focus {
  border-color: #333;
  box-shadow: 0 0 0.05rem 0.1rem #e6e6e6;
}

.selectedTime {
  border-color: #1d4a47;
  background-color: #1d4a47;
  color: #fefaee;
}
