.day {
  width: 100%;
  height: 100%;
  transition-property: background-color, border-color, box-shadow, color;
  transition-duration: 0.3s;
  border: 1px solid transparent;
  border-radius: 25%;
  color: #b5bec6;
  font-size: 0.7rem;
  font-weight: 500;
  line-height: 16px;
  cursor: not-allowed;
  padding: 0.25rem;
  background-color: transparent;
}
.available {
  color: #17a1fa;
  cursor: pointer;
}
.notAvailable {
  cursor: not-allowed;
}
.available:hover,
.available:focus {
  border-color: #333;
  box-shadow: 0 0 1px 2px #e6e6e6;
}
.selected {
  background-color: #ffb21c;
  color: #fefaee;
  border: none;
  box-shadow: none;
}
