.select {
  display: flex;
  flex-flow: column nowrap;
  row-gap: 0.37rem;
}

.select label {
  padding: 0 0.1rem;
  font-size: 0.9rem;
  line-height: 1;
  color: #333;
  font-weight: bold;
}

.select select {
  width: 100%;
  padding: 0.9rem 2.6rem 0.9rem 1.2rem;
  transition-property: border-color, box-shadow, color;
  transition-duration: 0.3s;
  border: 0.05rem solid transparent;
  border-radius: 0.4rem;
  font-weight: 500;
  line-height: 0.65rem;
  text-overflow: ellipsis;
  cursor: pointer;
}

.select select:hover,
.select select:hover {
  border-color: #333;
  box-shadow: 0 0 0.05rem 0.1rem #e6e6e6;
}

.select svg {
  position: relative;
  right: 0.75rem;
  justify-self: end;
  pointer-events: none;
}

.info {
  margin: auto 0.5rem;
}

.control {
  display: grid;
  align-items: center;
}

.control > * {
  grid-column: 1 / 1;
  grid-row: 1;
}

/* stylelint-disable-next-line no-descending-specificity */
.invalid select {
  border-color: #de5072;
}
