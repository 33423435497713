.header {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  margin: -10px -10px 10px;
  font-size: 0.8rem;
  font-weight: 500;
  line-height: 1;
}
.header svg {
  width: 16px;
  height: 16px;
}
.header button {
  display: inline-flex;
  padding: 9px;
  transition-property: border-color, box-shadow;
  transition-duration: 0.3s;
  border: 1px solid transparent;
  border-radius: 14px;
  color: #d5dce2;
  cursor: pointer;
  background-color: transparent;
}
.header button:hover,
.header button:focus {
  border-color: #333;
  box-shadow: 0 0 1px 2px #e6e6e6;
  color: #b5bec6;
}
.header button:last-of-type {
  transform: rotate(180deg);
}
.header .month {
  background-color: #1d4a47;
  color: #fefaee;
  padding: 0.325rem;
  border-radius: 5px;
  margin: 0;
}
