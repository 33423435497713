.bookingCalendar {
  display: flex;
  flex-flow: column nowrap;
  row-gap: 0.37rem;
}

.label {
  padding: 0.6rem 0;
  font-size: 14px;
  line-height: 1;
  font-weight: 600;
  margin: 0.25rem 0;
}
