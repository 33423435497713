.login-container {
  max-width: 400px;
  margin: 10px auto;
  padding: 20px;
  margin-top: 150px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login-title {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
}

.login-logo img {
  width: 100px;
  margin-bottom: 20px;
}

.form-item {
  margin-bottom: 16px;
}

.submit-button {
  width: 100%;
}

