.form {
  height: 100%;
}

.formsFields {
  height: 90%;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.nextButton {
  padding: .33rem 2rem;
  transition-property: border-color, box-shadow, color;
  transition-duration: 0.3s;
  border: 1px solid #ffb21c;
  border-radius: 0.4rem;
  background-color: #ffb21c;
  color: black;
  font-weight: 600;
  order: 2;
  cursor: pointer;
}

.nextButton:hover,
.nextButton:focus {
  border-color: #333;
  box-shadow: 0 0 0.05rem 0.1rem #e6e6e6;
}

.cancelButton {
  padding: 0 2rem;
  transition-property: border-color, box-shadow, color;
  transition-duration: 0.3s;
  order: 1;
  border: 0.05rem solid #333;
  border-radius: 0.4rem;
  background-color: transparent;
  color: black;
  font-weight: 600;
  cursor: pointer;
}

.cancelButton:hover,
.cancelButton:focus {
  border-color: #333;
  background-color: #ffb21c;
  box-shadow: 0 0 0.05rem 0.1rem #e6e6e6;
}

.ant-modal-title {
  font-size: 24px !important;
}
