.days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  column-gap: 6px;
  row-gap: 8px;
  margin: 0;
}
.days li {
  aspect-ratio: 1 / 1;
}
