.column {
  grid-column: span 1;
}

.lg1 {
  grid-column: span 1;
}

.lg2 {
  grid-column: span 2;
}

.lg3 {
  grid-column: span 3;
}

.lg4 {
  grid-column: span 4;
}

.lg5 {
  grid-column: span 5;
}
