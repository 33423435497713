.days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  padding: 4px 0;
  color: #b5bec6;
  font-size: 0.5rem;
  font-weight: 700;
  letter-spacing: 1.5px;
  line-height: 12px;
  text-transform: uppercase;
  list-style: none;
  font-size: 10px;
}

.days li {
  text-align: center;
}
